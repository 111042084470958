exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-account-index-jsx": () => import("./../../../src/pages/account/index.jsx" /* webpackChunkName: "component---src-pages-account-index-jsx" */),
  "component---src-pages-account-login-jsx": () => import("./../../../src/pages/account/login.jsx" /* webpackChunkName: "component---src-pages-account-login-jsx" */),
  "component---src-pages-account-register-jsx": () => import("./../../../src/pages/account/register.jsx" /* webpackChunkName: "component---src-pages-account-register-jsx" */),
  "component---src-pages-account-reset-jsx": () => import("./../../../src/pages/account/reset.jsx" /* webpackChunkName: "component---src-pages-account-reset-jsx" */),
  "component---src-pages-app-jsx": () => import("./../../../src/pages/app/[...].jsx" /* webpackChunkName: "component---src-pages-app-jsx" */),
  "component---src-pages-blogs-magazine-shopify-article-handle-jsx": () => import("./../../../src/pages/blogs/magazine/{ShopifyArticle.handle}.jsx" /* webpackChunkName: "component---src-pages-blogs-magazine-shopify-article-handle-jsx" */),
  "component---src-pages-book-now-chesterfield-alt-index-jsx": () => import("./../../../src/pages/book-now-chesterfield-alt/index.jsx" /* webpackChunkName: "component---src-pages-book-now-chesterfield-alt-index-jsx" */),
  "component---src-pages-book-now-chesterfield-index-jsx": () => import("./../../../src/pages/book-now-chesterfield/index.jsx" /* webpackChunkName: "component---src-pages-book-now-chesterfield-index-jsx" */),
  "component---src-pages-book-now-mansfield-alt-index-jsx": () => import("./../../../src/pages/book-now-mansfield-alt/index.jsx" /* webpackChunkName: "component---src-pages-book-now-mansfield-alt-index-jsx" */),
  "component---src-pages-book-now-mansfield-index-jsx": () => import("./../../../src/pages/book-now-mansfield/index.jsx" /* webpackChunkName: "component---src-pages-book-now-mansfield-index-jsx" */),
  "component---src-pages-cart-jsx": () => import("./../../../src/pages/cart.jsx" /* webpackChunkName: "component---src-pages-cart-jsx" */),
  "component---src-pages-chesterfield-contact-index-jsx": () => import("./../../../src/pages/chesterfield-contact/index.jsx" /* webpackChunkName: "component---src-pages-chesterfield-contact-index-jsx" */),
  "component---src-pages-collections-index-jsx": () => import("./../../../src/pages/collections/index.jsx" /* webpackChunkName: "component---src-pages-collections-index-jsx" */),
  "component---src-pages-favourites-jsx": () => import("./../../../src/pages/favourites.jsx" /* webpackChunkName: "component---src-pages-favourites-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-mansfield-contact-index-jsx": () => import("./../../../src/pages/mansfield-contact/index.jsx" /* webpackChunkName: "component---src-pages-mansfield-contact-index-jsx" */),
  "component---src-pages-mansfield-opening-offer-index-jsx": () => import("./../../../src/pages/mansfield-opening-offer/index.jsx" /* webpackChunkName: "component---src-pages-mansfield-opening-offer-index-jsx" */),
  "component---src-pages-search-jsx": () => import("./../../../src/pages/search.jsx" /* webpackChunkName: "component---src-pages-search-jsx" */),
  "component---src-pages-shopify-collection-handle-jsx": () => import("./../../../src/pages/{ShopifyCollection.handle}.jsx" /* webpackChunkName: "component---src-pages-shopify-collection-handle-jsx" */),
  "component---src-pages-shopify-page-handle-jsx": () => import("./../../../src/pages/{ShopifyPage.handle}.jsx" /* webpackChunkName: "component---src-pages-shopify-page-handle-jsx" */),
  "component---src-pages-shopify-product-handle-jsx": () => import("./../../../src/pages/{ShopifyProduct.handle}.jsx" /* webpackChunkName: "component---src-pages-shopify-product-handle-jsx" */),
  "component---src-templates-blogs-jsx": () => import("./../../../src/templates/blogs.jsx" /* webpackChunkName: "component---src-templates-blogs-jsx" */),
  "component---src-templates-pages-carousels-jsx": () => import("./../../../src/templates/pages/carousels.jsx" /* webpackChunkName: "component---src-templates-pages-carousels-jsx" */),
  "component---src-templates-pages-cols-2-jsx": () => import("./../../../src/templates/pages/cols_2.jsx" /* webpackChunkName: "component---src-templates-pages-cols-2-jsx" */)
}

