import * as React from "react"
import fetch from "isomorphic-fetch"
import Client from "shopify-buy"
import { urqlClient } from './search-provider'

const client = Client.buildClient(
  {
    domain: process.env.GATSBY_SHOPIFY_STORE_URL,
    storefrontAccessToken: process.env.GATSBY_STOREFRONT_ACCESS_TOKEN,
  },
  fetch
)

const defaultValues = {
  cart: [],
  isOpen: false,
  loading: false,
  onOpen: () => {},
  onClose: () => {},
  addLineItemsToCart: () => {},
  addVariantToCart: () => {},
  addCoordsToCart: () => {},
  addProductToRecentView: () => {},
  toggleFavorite: () => {},
  resetFavorites: () => {},
  removeLineItem: () => {},
  updateLineItem: () => {},
  updateLineItems: () => {},
  storeToken: () => {},
  revokeToken: () => {},
  storeCollectionFilters: () => {},
  clearFilters: () => {},
  acceptCookie: () => {},
  triggerAddCoords: () => {},
  client,
  checkout: {
    lineItems: [],
  },
  recentProducts: [],
  favorites: [],
  token: {},
  collectionFilters: [],
  cookieAccepted: true,
  addCoords: false
}

export const StoreContext = React.createContext(defaultValues)

const isBrowser = typeof window !== `undefined`
const localStorageKey = `shopify_checkout_id`

export const StoreProvider = ({ children }) => {
  const [checkout, setCheckout] = React.useState(defaultValues.checkout)
  const [loading, setLoading] = React.useState(false)
  const [didJustAddToCart, setDidJustAddToCart] = React.useState(false)
  const [recentProducts, setRecentProducts] = React.useState(defaultValues.recentProducts)
  const [favorites, setFavorites] = React.useState(defaultValues.favorites)
  const [token, setToken] = React.useState(defaultValues.token)
  const [collectionFilters, setCollectionFilters] = React.useState(defaultValues.collectionFilters)
  const [cookieAccepted, setCookieAccepted] = React.useState(defaultValues.cookieAccepted)
  const [addCoords, setAddCoords] = React.useState(defaultValues.addCoords)

  const setCheckoutItem = (checkout) => {
    if (isBrowser) {
      localStorage.setItem(localStorageKey, checkout.id)
    }

    setCheckout(checkout)
  }

  // init context
  React.useEffect(() => {
    // set recently reviewed products
    const recentTemps = JSON.parse(localStorage.getItem('recently_viewed')) || []
    const filteredRecentTemps = []
    recentTemps.forEach(item => {
      const now = new Date()
      if (parseInt(item.expiresAt) > now.valueOf()) {
        filteredRecentTemps.push(item)
      }
    })
    if (filteredRecentTemps.length !== recentTemps.length) localStorage.setItem('recently_viewed', JSON.stringify(filteredRecentTemps))
    setRecentProducts(filteredRecentTemps)

    // set favorites
    const favoritesTemps = JSON.parse(localStorage.getItem('favorites')) || []
    setFavorites(favoritesTemps)

    // set collection filters
    const filtersTemp = JSON.parse(localStorage.getItem('collection_filters')) || []
    const filteredFiltersTemp = []
    filtersTemp.forEach(item => {
      const now = new Date()
      if (parseInt(item.expiresAt) > now.valueOf()) {
        filteredFiltersTemp.push(item)
      }
    })
    if (filteredFiltersTemp.length !== filtersTemp.length) localStorage.setItem('filters', JSON.stringify(filteredFiltersTemp))
    setCollectionFilters(filteredFiltersTemp)

    // set customer access token
    const tempToken = JSON.parse(localStorage.getItem('token')) || []
    const expiresAt = new Date(tempToken?.expiresAt)
    const now = new Date()

    if (expiresAt.valueOf() <= now.valueOf()) localStorage.removeItem('token') // check whether or not the token expired
    else setToken(tempToken)

    // check if cookie is accepted
    const cookieTemp = localStorage.getItem('cookie:accepted')
    setCookieAccepted(cookieTemp === 'true' ? true : false)

    // initialize checkout
    const initializeCheckout = async () => {
      const existingCheckoutID = isBrowser
        ? localStorage.getItem(localStorageKey)
        : null

      if (existingCheckoutID && existingCheckoutID !== `null`) {
        try {
          const existingCheckout = await client.checkout.fetch(
            existingCheckoutID
          )
          if (!existingCheckout.completedAt) {
            existingCheckout.lineItems.map(lineItem => {
              const variantId = process.env.NODE_ENV === 'development' ? lineItem.variant.id : window.atob(lineItem.variant.id)
              const handle = localStorage.getItem(variantId)
              lineItem.handle = handle

              return lineItem
            })
            setCheckoutItem(existingCheckout)
            return
          }
        } catch (e) {
          localStorage.setItem(localStorageKey, null)
        }
      }

      const newCheckout = await client.checkout.create()
      setCheckoutItem(newCheckout)
    }

    initializeCheckout()
  }, [])

  const addLineItemsToCart = (lineItemsToInput) => {
    setLoading(true)

    const checkoutID = checkout.id

    if (isBrowser) {
      lineItemsToInput.forEach(item => {
        console.log('[item.variantId]', item.variantId)
        localStorage.setItem(window.atob(item.variantId), item.handle)
      })
    }

    const lineItemsToUpdate = lineItemsToInput.map(item => {
      return {
        variantId: item.variantId,
        quantity: item.quantity
      }
    })

    return client.checkout
      .addLineItems(checkoutID, lineItemsToUpdate)
      .then((res) => {
        const checkoutData = res
        checkoutData.lineItems.map(item => {
          lineItemsToInput.forEach(inputItem => {
            if (inputItem.belongsToProductId && inputItem.isCoords && item.variant.id === window.atob(inputItem.variantId)) {
              localStorage.setItem(`coords_${inputItem.belongsToProductId}`, item.id)
            }
            if (inputItem.belongsToProductId && item.variant.id === window.atob(inputItem.variantId)) {
              localStorage.setItem(item.id, inputItem.belongsToProductId)
            }
            if (item.variant.id === window.atob(inputItem.variantId)) return item.handle = inputItem.handle
          })

          return item
        })
        setCheckout(checkoutData)
        setLoading(false)
        setDidJustAddToCart(true)
        setTimeout(() => setDidJustAddToCart(false), 3000)
      })
  }

  const addVariantToCart = (variantId, handle, quantity) => {
    setLoading(true)

    const checkoutID = checkout.id

    const lineItemsToUpdate = [
      {
        variantId,
        quantity: parseInt(quantity, 10),
      },
    ]

    if (isBrowser) localStorage.setItem(variantId, handle)

    return client.checkout
      .addLineItems(checkoutID, lineItemsToUpdate)
      .then((res) => {
        const checkoutData = res
        checkoutData.lineItems.map(item => item.handle = handle)
        setCheckout(checkoutData)
        setLoading(false)
        setDidJustAddToCart(true)
        setTimeout(() => setDidJustAddToCart(false), 3000)
      })
  }

  const addCoordsToCart = (variantId, handle, quantity, belongsToId) => {
    setLoading(true)

    const checkoutID = checkout.id

    const lineItemsToUpdate = [
      {
        variantId,
        quantity: parseInt(quantity, 10),
      },
    ]    

    if (isBrowser) {
      localStorage.setItem(window.atob(variantId), handle)
    }

    return client.checkout
      .addLineItems(checkoutID, lineItemsToUpdate)
      .then((res) => {
        const checkoutData = res
        checkoutData.lineItems.map(item => item.handle = handle)
        checkoutData.lineItems.forEach(item => {
          localStorage.setItem(`coords_${belongsToId}`, item.id)
        })
        
        setCheckout(checkoutData)
        setLoading(false)
        setDidJustAddToCart(true)
        setTimeout(() => setDidJustAddToCart(false), 3000)
      })
  }

  const addProductToRecentView = (title, handle, image) => {
    setLoading(true)
    if (isBrowser) {
      let dupIndex = 999999
      const tmp = JSON.parse(localStorage.getItem('recently_viewed')) || []
      tmp.forEach((product, index) => {
        if (product.handle === handle) dupIndex = index
      })
      if (dupIndex !== 999999) tmp.splice(dupIndex, 1)
      const now = new Date()
      const expresAt = now.setDate(now.getDate() + 100)

      tmp.push({
        title: title,
        handle: handle,
        image: image,
        expiresAt: expresAt
      })
      setRecentProducts(tmp)
      localStorage.setItem('recently_viewed', JSON.stringify(tmp))
    }
    setLoading(false)
  }

  const toggleFavorite = (title, handle, image) => {
    setLoading(true)
    if (isBrowser) {
      let dupIndex = 999999
      const tmp = JSON.parse(localStorage.getItem('favorites')) || []
      tmp.forEach((product, index) => {
        if (product.handle === handle) dupIndex = index
      })
      if (dupIndex !== 999999) tmp.splice(dupIndex, 1)
      else tmp.push({ title: title, handle: handle, image: image })
      setFavorites(tmp)
      localStorage.setItem('favorites', JSON.stringify(tmp))
    }
    setLoading(false)
  }

  const resetFavorites = (favorites) => {
    setLoading(true)
    if (isBrowser) {
      setFavorites(favorites)
      localStorage.setItem('favorites', JSON.stringify(favorites))
    }
    setLoading(false)
  }

  const removeLineItem = (checkoutID, lineItemID, productId) => {
    setLoading(true)

    let isCoordsItemExist = false
    const coordsLineItemId = localStorage.getItem(`coords_${productId}`)
    console.log('[coordsLineItemId]', coordsLineItemId)

    checkout.lineItems.forEach(lineItem => {
      if (lineItem.id === coordsLineItemId) isCoordsItemExist = true
    })

    return client.checkout
      .removeLineItems(checkoutID, isCoordsItemExist ? [lineItemID, coordsLineItemId] : [lineItemID])
      .then((res) => {
        setCheckout(res)
        localStorage.removeItem(`coords_${productId}`)
        setLoading(false)
      })
  }

  const updateLineItems = (checkoutID, lineItemsInput) => {
    setLoading(true)

    const lineItemsToUpdate = lineItemsInput.map(item => {
      return {
        id: item.itemID,
        quantity: parseInt(item.value, 10)
      }
    })

    return client.checkout
      .updateLineItems(checkoutID, lineItemsToUpdate)
      .then((res) => {
        setCheckout(res)
        setLoading(false)
      })
  }

  const updateLineItem = (checkoutID, lineItemID, quantity) => {
    setLoading(true)

    const lineItemsToUpdate = [
      { id: lineItemID, quantity: parseInt(quantity, 10) },
    ]

    return client.checkout
      .updateLineItems(checkoutID, lineItemsToUpdate)
      .then((res) => {
        setCheckout(res)
        setLoading(false)
      })
  }

  const storeToken = (token) => {
    setToken(token)
    if (isBrowser) {
      localStorage.setItem('token', JSON.stringify(token))
    }
  }

  const revokeToken = async () => {
    try {
      const tokenToRemove = token?.accessToken
      setToken({})
      if (isBrowser) localStorage.removeItem('token')
  
      const tokenDeleteMutation = `
      mutation customerAccessTokenDelete($customerAccessToken: String!) {
        customerAccessTokenDelete(customerAccessToken: $customerAccessToken) {
          deletedAccessToken
          deletedCustomerAccessTokenId
          userErrors {
            field
            message
          }
        }
      }
      `
  
      await urqlClient.mutation(tokenDeleteMutation, {
        customerAccessToken: tokenToRemove
      }).toPromise()
    }
    catch (err) {
      console.error(err.message)
    }
  }

  const storeCollectionFilters = (handle, appliedFilters) => {
    try {
      const now = new Date()
      const expiresAt = now.setDate(now.getDate() + 1)
  
      const filterItem = {
        handle,
        appliedFilters,
        expiresAt
      }
      if (isBrowser) {
        let dupIndex = 999999
        const tmp = JSON.parse(localStorage.getItem('collection_filters')) || []
        tmp.forEach((item, index) => {
          if (item.handle === handle) dupIndex = index
        })
        if (dupIndex !== 999999) tmp.splice(dupIndex, 1)
        
        tmp.push(filterItem)
        setCollectionFilters(tmp)
        localStorage.setItem('collection_filters', JSON.stringify(tmp))
      }
    }
    catch (err) {
      console.log('[err]', err)
    }
  }

  const clearFilters = (handle) => {
    try {
      if (isBrowser) {
        const storedFilters = JSON.parse(localStorage.getItem('collection_filters')) || []
        let collectionIndex = 999999
        for (let i = 0; i < storedFilters.length; i++) {
          if (storedFilters[i].handle === handle) {
            collectionIndex = i
            break
          }
        }

        if (collectionIndex !== 999999) {
          storedFilters.splice(collectionIndex, 1)
          setCollectionFilters(storedFilters)
          localStorage.setItem('collection_filters', JSON.stringify(storedFilters))
        }
      }
    }
    catch (err) {
      console.log('[err]', err)
    }
  }

  const acceptCookie = () => {
    try {
      if (isBrowser) {
        localStorage.setItem('cookie:accepted', true)
        setCookieAccepted(true)
      }
    }
    catch (err) {
      console.log('[err]', err)
    }
  }

  const triggerAddCoords = (val) => {
    setAddCoords(val)
  }

  return (
    <StoreContext.Provider
      value={{
        ...defaultValues,
        addVariantToCart,
        addLineItemsToCart,
        addCoordsToCart,
        addProductToRecentView,
        toggleFavorite,
        resetFavorites,
        removeLineItem,
        updateLineItem,
        updateLineItems,
        storeToken,
        revokeToken,
        storeCollectionFilters,
        acceptCookie,
        triggerAddCoords,
        checkout,
        loading,
        didJustAddToCart,
        recentProducts,
        favorites,
        token,
        collectionFilters,
        clearFilters,
        cookieAccepted,
        addCoords
      }}
    >
      {children}
    </StoreContext.Provider>
  )
}
